import { useEffect, useRef, useState } from 'react';

interface LazyLoadImageProps {
  imgSrc: string;
  imgAlt: any;
  imgHeight: number;
  imgWidth: number;
  aspectHeight?: number;
  aspectWidth?: number;
  imgClass?: string;
  imgTitle?: string;
}

const useLazyLoadImage = (callback: () => void, rootMargin = '0') => {
  const [isVisible, setIsVisible] = useState(false);
  const targetRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (
      typeof window === 'undefined' ||
      typeof window.IntersectionObserver === 'undefined'
    ) {
      return;
    }
    const observer = new window.IntersectionObserver(
      (entries) => {
        const elem = entries[0];
        if (elem.isIntersecting) {
          setIsVisible(true);
          callback();
          observer.unobserve(elem.target);
        }
      },
      {
        rootMargin,
      }
    );

    if (targetRef.current) {
      observer.observe(targetRef.current);
    }

    return () => {
      if (targetRef.current) {
        observer.unobserve(targetRef.current);
      }
    };
  }, [callback, rootMargin]);

  return { isVisible, targetRef };
};

const defaultPlaceholderBase64 = `data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7`;

const LazyImage = (props: LazyLoadImageProps) => {
  const { isVisible, targetRef } = useLazyLoadImage(() => {}, '10px');

  const calculatedAspectRatio =
    props.aspectHeight && props.aspectWidth
      ? props.aspectWidth + ' / ' + props.aspectHeight
      : props.imgWidth + ' / ' + props.imgHeight;

  return (
    <span ref={targetRef}>
      <img
        src={isVisible ? props.imgSrc : defaultPlaceholderBase64}
        alt={props.imgAlt}
        width={props.imgWidth}
        height={props.imgHeight}
        style={{ aspectRatio: calculatedAspectRatio }}
        className={props.imgClass}
        title={props.imgTitle}
      />
    </span>
  );
};

export default LazyImage;
