import { RadialProgress } from '@license-admin/boldfjcomponents';
import { CompName, Logo, TypeWrap } from './styled';

const CurrentlyHiringType = () => {
  return (
    <div>
      <Logo src="/images/emburse.png" alt="emburse-comp" />
      <CompName>Emburse</CompName>
      <TypeWrap>
        <RadialProgress progress={100} />
        <p>Remote Work Options</p>
      </TypeWrap>
      <TypeWrap>
        <RadialProgress progress={0} />
        <p>Flexible Schedule</p>
      </TypeWrap>
    </div>
  );
};

export default CurrentlyHiringType;
