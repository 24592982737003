import { checkIsFJFeatureAllowed, readCookie } from '@components/common';
import { WIDGETS_NAME } from '@components/common/constant';
import {
  MPRWidgetWrapperLink,
  SubSidebarWidget,
} from '@components/common/styled';
import CurrentlyHiringType from '@components/widgets/currentlyHiringType';
import UpComingEvents from '@components/widgets/upComingEvents';

import {
  CurrentlyHiring,
  FollowedCompanies,
  InNews,
  NewsLetter,
  PromoBanner,
  RelatedArticles,
  RelatedCompanySearch,
  SidebarWidget,
  SimilarJobs,
  SuccessStories,
} from '@license-admin/boldfjcomponents';
import { isPortalContent } from 'helper';
import { useEffect, useState } from 'react';

type IWridgetsProps = {
  widgetsData: any;
  widgetsName: Array<string>;
  articleSeeMore?: string;
  isMobileView?: boolean;
  isHostedPage?: boolean;
};

export default function WidgetsWrapper(props: IWridgetsProps) {
  const blob_path = `${process.env.NEXT_PUBLIC_CONTENT_URL}/images`;
  const {
    widgetsData: {
      newsData,
      successStoriesList,
      relatedArt,
      followedCom,
      compHiringInMembersState,
      upcomingEvents,
      similarJobs,
    },
    widgetsName,
    articleSeeMore,
    isMobileView,
  } = props;
  const [showNewsletterWidget, setShowNewsletterWidget] = useState(true);

  useEffect(() => {
    if (readCookie('fj_newsletter') === '1') {
      setShowNewsletterWidget(false);
    }
  }, []);

  if (!checkIsFJFeatureAllowed()) {
    return null;
  }

  return (
    <>
      {widgetsName.map((widget: string, index: number) => {
        switch (widget) {
          case WIDGETS_NAME.NEWS:
            return (
              <SidebarWidget
                isLazyImage
                key={WIDGETS_NAME.NEWS + index}
                title={`${isPortalContent}in the News`}
                seeAllTitle="More of This Week's News"
                seeAllUrl="https://remote.co/latestnews"
                titleCenter
                extraSpace
                plainLink
                noSpace
              >
                <InNews newsData={newsData} />
              </SidebarWidget>
            );
          case WIDGETS_NAME.SUCCESS_STORIES:
            return (
              <SidebarWidget
                key={WIDGETS_NAME.SUCCESS_STORIES + index}
                isLazyImage
                title="Success Stories Just In!"
                titleCenter
                extraSpace
                seeAllTitle="Read More Success Stories"
                seeAllUrl="/reviews-success-stories"
              >
                <SuccessStories data={successStoriesList} />
              </SidebarWidget>
            );
          case WIDGETS_NAME.CURRENTLY_HIRING:
            return (
              <SidebarWidget
                key={WIDGETS_NAME.CURRENTLY_HIRING + index}
                isLazyImage
                title={`Currently Hiring on ${isPortalContent}`}
                titleCenter
                seeAllTitle="See All New Jobs"
                seeAllUrl="/remote-jobs/legitimate-work-from-home-jobs-hiring-now"
                plainLink
                isLightText
                plainBdr
              >
                <CurrentlyHiring newsData={newsData} />
              </SidebarWidget>
            );
          case WIDGETS_NAME.PROMO_BANNER:
            return (
              <PromoBanner
                key={WIDGETS_NAME.PROMO_BANNER + index}
                imgSrc={`${process.env.NEXT_PUBLIC_CONTENT_URL}/images/img-sidebar-cta.svg`}
                width={273}
                height={273}
                isLazyImage
                alt="rocket ship image"
                title="Want a Great Remote <br /> or Flexible Job?"
                description="Save time and find higher-quality jobs than on other sites, guaranteed."
                btnText="Join FlexJobs Now!"
                btnLink="/signup"
                buttonType="tertiary"
              />
            );
          case WIDGETS_NAME.NEWS_LETTER:
            return (
              showNewsletterWidget && (
                <SidebarWidget
                  key={WIDGETS_NAME.NEWS_LETTER + index}
                  isLazyImage
                  title="Weekly Newsletter"
                  imgSrc={`${process.env.NEXT_PUBLIC_CONTENT_URL}/images/newletter-graphic.svg`}
                  alt="Weekly Newsletter icon"
                  titleCenter
                  imgWidth={64}
                  imgHeight={68}
                >
                  <NewsLetter />
                </SidebarWidget>
              )
            );
          case WIDGETS_NAME.RELATED_ARTICLES:
            return (
              <SubSidebarWidget key={index}>
                {articleSeeMore ? (
                  <SidebarWidget
                    key={WIDGETS_NAME.RELATED_ARTICLES + index}
                    isLazyImage
                    title="Related Articles"
                    // titleCenter
                    seeAllTitle="See More Articles"
                    seeAllUrl={articleSeeMore}
                    plainLink
                    // fontWeight={400}
                    extraSpace
                  >
                    <RelatedArticles
                      data={relatedArt}
                      isMobileView={isMobileView}
                    />
                  </SidebarWidget>
                ) : (
                  <SidebarWidget
                    key={WIDGETS_NAME.RELATED_ARTICLES + index}
                    isLazyImage
                    title="Related Articles"
                    titleCenter
                    extraSpace
                  >
                    <RelatedArticles
                      data={relatedArt}
                      isMobileView={isMobileView}
                    />
                  </SidebarWidget>
                )}
              </SubSidebarWidget>
            );
          case WIDGETS_NAME.FOLLOWED_COMPANIES:
            return followedCom.length === 0 ? (
              <></>
            ) : (
              <SidebarWidget
                isLazyImage
                key={WIDGETS_NAME.FOLLOWED_COMPANIES + index}
                title="Followed Companies"
                titleCenter
              >
                <FollowedCompanies
                  data={followedCom}
                  emptyStateImage={`${process.env.NEXT_PUBLIC_CONTENT_URL}/images/icons/streamline-thin/streamline-icon-real-estate-building-person.svg`}
                />
              </SidebarWidget>
            );

          case WIDGETS_NAME.COMPANY_HIRING:
            return (
              compHiringInMembersState.data.state !== null && (
                <SidebarWidget
                  key={WIDGETS_NAME.COMPANY_HIRING + index}
                  isLazyImage
                  title={`Companies Hiring in ${compHiringInMembersState.data.state}`}
                  titleCenter
                  seeAllTitle="See All"
                  seeAllUrl={`/company-guide/${compHiringInMembersState.data.stateSlug}`}
                  plainLink
                  fontWeight={400}
                >
                  <FollowedCompanies
                    data={compHiringInMembersState.data.companyShortInfo}
                    companyHiring
                    emptyStateImage={`${process.env.NEXT_PUBLIC_CONTENT_URL}/images/icons/streamline-thin/streamline-icon-real-estate-building-person.svg`}
                  />
                </SidebarWidget>
              )
            );
          case WIDGETS_NAME.RELATED_COMPANY_SEARCH:
            return (
              <SidebarWidget
                key={WIDGETS_NAME.RELATED_COMPANY_SEARCH + index}
                isLazyImage
                title="Related Company Searches"
                noSideSpace
              >
                <RelatedCompanySearch blob_path={blob_path} />
              </SidebarWidget>
            );
          case WIDGETS_NAME.MPR:
            return (
              <MPRWidgetWrapperLink
                key={WIDGETS_NAME.MPR + index}
                href="https://www.myperfectresume.com/build-resume?utm_source=flexjobs&amp;utm_medium=referral&amp;utm_campaign=flexjobs-rail"
                target="_blank"
                rel="external nofollow noopener noreferrer"
              >
                <img
                  src={`${process.env.NEXT_PUBLIC_CONTENT_URL}/images/img-sidebar-mpr.png`}
                  alt="MPR Resume Builder"
                  title="MPR Resume Builder"
                  className="mpr-widget"
                />
              </MPRWidgetWrapperLink>
            );
          case WIDGETS_NAME.SIMILAR_JOBS:
            return (
              <SidebarWidget
                key={WIDGETS_NAME.SIMILAR_JOBS + index}
                isLazyImage
                title="Similar Jobs"
                titleIconAlt="Similar Jobs icon"
                titleIcon={`${process.env.NEXT_PUBLIC_CONTENT_URL}/images/streamline-icon-briefcase-star.svg`}
                titleIconWidth={25}
                titleIconHeight={23}
              >
                <SimilarJobs similarJobs={similarJobs} />
              </SidebarWidget>
            );
          case WIDGETS_NAME.UPCOMING_EVENT:
            return (
              <SidebarWidget
                key={WIDGETS_NAME.UPCOMING_EVENT + index}
                isLazyImage
                title="Upcoming Events"
                seeAllTitle="See All Upcoming Events"
                seeAllUrl="/events?time=upcoming"
                plainLink
              >
                <UpComingEvents upcomingEvents={upcomingEvents} />
              </SidebarWidget>
            );
          case WIDGETS_NAME.CURRENTLY_HIRING_TYPE:
            return (
              <SidebarWidget
                key={WIDGETS_NAME.CURRENTLY_HIRING_TYPE + index}
                isLazyImage
                title={`Currently Hiring in ${isPortalContent}`}
                seeAllTitle="See Current Jobs"
                seeAllUrl="/remote-jobs/company/emburse"
                plainLink
              >
                <CurrentlyHiringType />
              </SidebarWidget>
            );
          default:
            return null;
        }
      })}
    </>
  );
}
