const days = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];
export const DateDifference = (targetDate: string) => {
  const currentDate = new Date();
  const targetDateStr = new Date(targetDate);

  // Calculate the time difference in milliseconds
  const timeDifference = targetDateStr.getTime() - currentDate.getTime();

  // Convert the time difference to days
  const daysDifference = Math.abs(
    Math.floor(timeDifference / (1000 * 60 * 60 * 24))
  );

  let differenceResp = '';
  if (daysDifference > 30) {
    differenceResp = '30+ days ago';
  } else if (daysDifference > 14) {
    differenceResp = '2 weeks ago';
  } else if (daysDifference === 1 || daysDifference === 0) {
    differenceResp = 'Today';
  } else if (daysDifference === 2) {
    differenceResp = 'Yesterday';
  } else {
    differenceResp = `${daysDifference} days ago`;
  }

  return {
    differenceResp,
    daysDifference,
  };
};

export const jobPostedDateFormatted = (value: string): string => {
  const newDate = new Date(value);

  const month: string | number =
    newDate.getMonth() > 8
      ? newDate.getMonth() + 1
      : '0' + (newDate.getMonth() + 1);
  const date: string | number =
    newDate.getDate() > 9 ? newDate.getDate() : '0' + newDate.getDate();
  const year: string = newDate
    .getFullYear()
    .toString()
    .substring(
      newDate.getFullYear().toString().length - 2,
      newDate.getFullYear().toString().length
    );
  return `${month}/${date}/${year}`;
};

const formatDateToHourMinutes = (date: Date, showAmPm: boolean = true) => {
  // Get hours and minutes
  const hours = date.getHours();
  const minutes = date.getMinutes();

  // Format hours and minutes with leading zeros
  const formattedHours = String(hours % 12 || 12).padStart(2, '0');
  const formattedMinutes = String(minutes).padStart(2, '0');

  // Determine AM/PM
  const amPm = hours >= 12 ? 'pm' : 'am';

  // Create the formatted time string
  const formattedTime = `${formattedHours}:${formattedMinutes} ${
    showAmPm ? amPm : ''
  }`;

  return formattedTime;
};

export const getEventDateFormat = (
  startDate: string,
  endDate: string,
  timeZone: string = 'UTC'
) => {
  const start = new Date(startDate);
  const end = new Date(endDate);

  // Convert start and end dates to the specified time zone
  start.toLocaleString('en-US', { timeZone });
  end.toLocaleString('en-US', { timeZone });

  // Define options for formatting the time
  const options: Intl.DateTimeFormatOptions = {
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  };

  // Format start and end times
  const formatter = new Intl.DateTimeFormat('en-US', options);
  const resStartTime = formatter.format(start);
  const resEndTime = formatter.format(end);

  // Get the time zone abbreviation for the specified time zone
  const timeZoneName = new Intl.DateTimeFormat('en', {
    timeZone,
    timeZoneName: 'short',
  })
    .formatToParts(start)
    .find((part) => part.type === 'timeZoneName')!.value;

  // Construct the result time string
  const resTime = `${resStartTime} - ${resEndTime} ${timeZoneName}`;

  // Return the formatted time
  return resTime;
};

export const getISO8601DatesDiff = (date: string) => {
  const currentDate = new Date();
  const previousDate = new Date(date);
  const timeDifferenceMs = Math.abs(
    currentDate.getTime() - previousDate.getTime()
  );
  const days = Math.floor(timeDifferenceMs / (1000 * 60 * 60 * 24));
  return days;
};
