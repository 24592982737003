import Mixin from '@styles/mixin';
import styled, { css } from 'styled-components';

export const TypeWrap = styled.div`
  ${Mixin.VerticalCenter()};
  column-gap: 0.8rem;
  margin-bottom: 1.6rem;
`;

export const Logo = styled.img`
  max-width: 9.6rem;
`;

export const CompName = styled.h5`
  margin-top: 0.8rem;
  ${({ theme: { CurrHiringTypeStyle } }) =>
    CurrHiringTypeStyle &&
    css`
      color: ${CurrHiringTypeStyle.Colors.title};
    `}
`;
