import { useEffect, useState } from 'react';

export const useOutsideClick = (
  ref: any,
  wrapperRef?: any,
  excludeRefs: any[] = [],
  ignoreTabKey?: boolean
) => {
  const [isOutsideClick, setIsOutsideClick] = useState<boolean>(false);

  useEffect(() => {
    function handleClickOutside(event: any) {
      const isExcluded = excludeRefs.some((excludeRef) => {
        return excludeRef?.current && excludeRef.current.contains(event.target);
      });

      const isScrollbarClick =
        document.documentElement.clientWidth <= event.clientX ||
        document.documentElement.clientHeight <= event.clientY;

      const isWrapperClick =
        wrapperRef &&
        wrapperRef.current &&
        wrapperRef.current.contains(event.target);

      const isExceptMeClick = event.target.classList.contains('show-content');

      if (
        ref &&
        ref.current &&
        !ref.current.contains(event.target) &&
        !isExcluded &&
        !isScrollbarClick &&
        !isWrapperClick &&
        !isExceptMeClick
      ) {
        setIsOutsideClick(true);
      } else {
        setIsOutsideClick(false);
      }
    }

    function handleKeyDown(event: KeyboardEvent) {
      const isTabKeyPressed = event.key === 'Tab';
      if (ignoreTabKey && isTabKeyPressed) return;

      setIsOutsideClick(false);
    }

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [ref, wrapperRef, excludeRefs, ignoreTabKey]);

  return isOutsideClick;
};
