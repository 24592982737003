import Mixin from '@styles/mixin';
import styled, { css } from 'styled-components';

export const TypeTitle = styled.h4`
  margin: 1.6rem 0 1rem;
`;

export const TypeWrap = styled.div`
  ${Mixin.VerticalCenter()};
  column-gap: 0.5rem;
  margin-top: 0.4rem;

  ${({ theme: { UpComingEventStyle } }) =>
    UpComingEventStyle &&
    css`
      color: ${UpComingEventStyle.Colors.type};
      font-size: ${UpComingEventStyle.FontSizes.type};
    `}
`;

export const ImgWrap = styled.div`
  img {
    object-fit: contain;
    aspect-ratio: auto !important;
  }
`;
