import { UpcomingEvents } from '@components/shared.types';
import { CustomCarousel, formatDate } from '@license-admin/boldfjcomponents';
import { getEventDateFormat } from '@utils/dateFormatter';
import LazyImage from 'helper/useLazyLoadImages';
import { ImgWrap, TypeTitle, TypeWrap } from './styled';

type Props = {
  upcomingEvents: UpcomingEvents[];
};

function UpComingEvents(props: Props) {
  return (
    <CustomCarousel
      itemsToShow={1}
      hideSideArrow={true}
      slidesToScroll={1}
      showIndicator
      autoplay
    >
      {props.upcomingEvents.map((upcomingEvent: UpcomingEvents) => {
        return (
          <div className="card-event" key={upcomingEvent.id}>
            <ImgWrap>
              <LazyImage
                imgSrc={upcomingEvent.imageUrl}
                imgAlt={'event-image'}
                imgWidth={277}
                imgHeight={185}
              />
            </ImgWrap>
            {/* <img src={upcomingEvent.imageUrl} alt="event-image" /> */}
            <TypeTitle>
              <a href={upcomingEvent.extRegistrationUrl}>
                {upcomingEvent.title}
              </a>
            </TypeTitle>
            <TypeWrap>
              <i className="fa fa-regular fa-calendar-days"></i>
              <span>
                {formatDate(
                  upcomingEvent.startDate,
                  'shortDateWithWeekDay',
                  true
                )}
              </span>
            </TypeWrap>
            <TypeWrap>
              <i className="fa fa-regular fa-clock-nine"></i>
              <span>
                {upcomingEvent.startDate && upcomingEvent.endDate
                  ? getEventDateFormat(
                      upcomingEvent.startDate,
                      upcomingEvent.endDate
                    )
                  : ''}
              </span>
            </TypeWrap>
          </div>
        );
      })}
    </CustomCarousel>
  );
}

export default UpComingEvents;
